import React, { useState, useEffect, useMemo, useCallback } from 'react'
import B2BLayout from '../../../layouts/b2b'
import { Table } from 'antd'
import { getColumns } from './columns'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { objectToUrl } from '../../utils'
import FiltersList from '../../../components/v2/filters'
import { State } from '../../../interfaces'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { HStack } from '../../../ui/Stack'
import { getJsonSettingsByKey, TableSettingsButton, TransferItem } from '../../../components/v2/table-settings-button'
import { getPrintPath } from '../../../components/v2/export-excel-button'
import { CustomersPrintButton } from '../ui/customers-print-button/customers-print-button'

const createSession = createSelector(
  (state: State) => state.session,
  sessionData => sessionData,
)

type TablePaginationPosition = 'bottomCenter'

interface IFilterProps {
  phone: null | string
  manager: null | number
  id: null | number
  organization: null | number
  office: null | number
  stage: null | string
  dates: null | string
  fio: null | string
  brands: null | number
  production_number: null | string
  additional: null | string
}

const CustomersList = () => {
  const history = useHistory()
  const [data, setData] = useState([])
  const [availableFilters, setAvailableFilters] = useState({
    organizations: [],
    offices: [],
    brands: [],
  })
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))
  const [currentFilters] = useState<IFilterProps>({
    phone: urlParams?.phone,
    manager: urlParams.manager ? +urlParams.manager : null,
    id: urlParams.id ? +urlParams.id : null,
    organization: urlParams.organization ? +urlParams.organization : null,
    office: urlParams.office ? +urlParams.office : null,
    stage: urlParams?.stage,
    dates: urlParams?.dates,
    fio: urlParams?.fio,
    brands: urlParams?.brands ? +urlParams.brands : null,
    production_number: urlParams?.production_number || null,
    additional: urlParams?.additional || null,
  })
  const { sessionData } = useSelector(createSession)
  const isLeader = sessionData?.roles.includes(25)
  const isGoLeader = sessionData?.roles.includes(16)
  const [loading, setLoading] = useState(false)
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [sorter, setSorter] = useState<any>({})
  const [filters, setFilters] = useState<filtersType[]>([])

  const { pathname } = useLocation()
  const jsonSettings = useSelector((state) => getJsonSettingsByKey(state, pathname))

  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    position: [bottomCenter],
  })

  /**
   * Хлебные крошки
   */
  const breadcrumbs = useMemo(
    () => [
      {
        href: '/cases',
        title: 'Контактный центр',
      },
      {
        href: location.pathname,
        title: 'Клиенты',
      },
    ],
    [location.pathname],
  )

  useEffect(() => {
    try {
      /* Пытаюсь получить стадии и фильтры */
      getAvailableFilters()
      /* Пытаюсь получить список */
      getData()
    } catch (e) {
      alert('Не удалось получить данные, пожалуйста, перезагрузите страницу')
    }
  }, [])

  /**
   * Обновляет состояние пагинации
   */
  const updatePagination = useCallback(
    newState => {
      setPagination({
        ...pagination,
        ...newState,
      })
    },
    [pagination, setPagination],
  )

  useEffect(() =>{
    setFilters(() => {
      const general = [
        {
          name: 'id',
          placeholder: 'ID',
          type: 'input-integer',
          value: currentFilters.id || undefined,
          options: {
            width:'170px'
          }
        },
        {
          name: 'fio',
          placeholder: 'Контактное лицо',
          type: 'input-string',
          value: currentFilters.fio || undefined,
        },
        {
          name: 'production_number',
          placeholder: 'Номер производственного заказа',
          type: 'input-string',
          value: currentFilters.production_number || undefined,
        },
        {
          name: 'additional',
          placeholder: 'Доп контакты',
          type: 'input-string',
          value: currentFilters.additional || undefined,
        },
        {
          name: 'phone',
          type: 'phone',
          value: currentFilters.phone || undefined,
        }
      ]
      const leader = [
        {
          name: 'office',
          placeholder: 'Офис',
          type: 'select',
          value: currentFilters.office || undefined,
          options: {
            enum: availableFilters && availableFilters?.offices || []
          }
        },
      ]
      const goLeader = [
        {
          name: 'brand',
          placeholder: 'Бренд',
          type: 'select',
          value: currentFilters.brands || undefined,
          options: {
            enum: availableFilters && availableFilters?.brands || []
          }
        },
        {
          name: 'organization',
          placeholder: 'Организация',
          type: 'select',
          value: currentFilters.organization || undefined,
          options: {
            enum: availableFilters && availableFilters?.organizations || []
          }
        },
        {
          name: 'dates',
          type: 'dates'
        },
      ]
      if (isGoLeader) {
        return [...general, ...leader, ...goLeader] as filtersType[]
      }
      /*
      if (isLeader) {
        return [...general, ...leader] as filtersType[]
      }
      */
      return general as filtersType[]
    })
  }, [availableFilters, isLeader, isGoLeader])

  /**
   * Получает список доступных фильтров для обращений
   */
  const getAvailableFilters = useCallback(() => {
    const enumsList = { types: ['organizations', 'brands', 'offices'] }
    interface IEnumResponse {
      id: number
      value: string
    }
    fetchAPI(`/api/enum-list?${objectToUrl(enumsList)}`)
      .then(enums => {
        const filteredEnums = {
          organizations: enums.organizations.map((i: IEnumResponse) => ({ value: i.id, label: i.value })),
          offices: enums.offices.map((i: IEnumResponse) => ({ value: i.id, label: i.value })),
          brands: enums.brands.map((i: IEnumResponse) => ({ value: i.id, label: i.value })),
        }
        setAvailableFilters(filteredEnums)
      })
      .catch(error => {
        console.error('Произошла ошибка при загрузке данных:', error)
      })
  }, [])

  /**
   * Получает записи
   */
  const getData = useCallback(async () => {
    setLoading(true)
    await fetchAPI(`/api/customers/get-list/${location.search}`)
      .then((response: Record<string, any>) => {
        const { data, pagination, sorter } = response
        if (data) {
          setPagination(prevState => ({
            ...prevState,
            ...pagination
          }))
          setSorter(sorter)
          setData(data)
        }
        setLoading(false)
      })
      .catch(error => {
        console.error('Произошла ошибка при загрузке данных:', error)
      })
  }, [])

  /**
   * Отслеживает изменение состояния таблицы
   *
   * @param pagination
   * @param filters
   * @param sorter
   */
  const handleTableChange = (pagination, _filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    let current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current,
      pageSize: pagination.pageSize,
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({ search: urlParams })
    getData()
  }

  /**
   * Отправляет на страницу деталки
   * @param record
   */
  const handleRowClick = record => {
    const {id} = record
    history.push(`/client-new/${id}`)
  }

  /**
   * Отслеживает состояние фильтров
   * @param filters
   */
  const handleFilterChange = (filters: IFilterProps) => {
    const obj = {...filters}
    /* в строку попадают только непустые строковые и положительные числовые значения */
    const newParams = Object.keys(obj).reduce((acc, key) => {
      if (
        (typeof obj[key] === 'string' && obj[key].length)
        ||
        (typeof obj[key] === 'number' && obj[key] > 0)
      ) {
        acc[key] = obj[key]
      }
      return acc
    }, {})

    const newUrlParams = new URLSearchParams(newParams).toString()
    history.push({ search: newUrlParams })
    getData()
  }

  const columns = useMemo(() => getColumns(isGoLeader, sorter), [isGoLeader, sorter])
  const viewColumns: typeof columns = useMemo(() => {
    if (jsonSettings && jsonSettings?.length > 0) {
      let result: any[] = []
      for (let i = 0; i < jsonSettings.length; i++) {
        // @ts-ignore
        let item = columns.find((column) => jsonSettings[i] === column.dataIndex)
        if (item) {
          result.push(item)
        }
      }
      return result
    }
    return columns
  }, [columns, jsonSettings])

  const excelPath = useMemo(() => {
    return getPrintPath('/api/customers/get-list', new URLSearchParams(location.search))
  }, [location.search])

  const settingsColumns = useMemo(() => columns.map(column => ({
    // @ts-ignore
    key: column?.dataIndex, title: column?.title,
  })), [columns]) as TransferItem[]

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <FiltersList
        filters={filters}
        onChange={handleFilterChange}
      />
      <HStack gap={'24'} align={'start'} justify={'end'} style={{marginBottom: '20px'}}>
        <CustomersPrintButton
          path={excelPath}
          columns={viewColumns}
        />
        <TableSettingsButton items={settingsColumns}/>
      </HStack>
      <Table
        showSorterTooltip={{overlay: 'Нажмите для сортировки', placement: 'bottom'}}
        columns={viewColumns}
        rowKey={r => r?.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        onRow={record => ({onClick: () => handleRowClick(record)})}
        scroll={{
          x: 'max-content',
          y: '72vh'
        }}
        size='small'
      />
    </B2BLayout>
  )
}

export default CustomersList
