import React, { useCallback, useRef, useMemo, useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import { ConfigProvider } from 'antd'
import locale from 'antd/es/locale/ru_RU'
import { IDate } from './interface'

export function InputDateTime({ value, onChange, UISchema }: IDate) {
  const ref = useRef<any>()
  const [localValue, setLocalValue] = useState<any>({})

  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])

  useEffect( () => {
    setLocalValue(value)
  }, [value])

  const isDisabledDatePrev = useMemo(() => {
    return UISchema?.options?.isDisabledDatePrev ?? true
  }, [UISchema])

  const picker = useMemo(() => {
    return UISchema?.options?.picker ?? ''
  }, [UISchema])

  const disabledDate = useCallback(date => {
    if(typeof isDisabledDatePrev === 'string') {
      if(isDisabledDatePrev){
        return moment(date).valueOf() <  moment(isDisabledDatePrev).valueOf()
      }
    }
    if(isDisabledDatePrev){
      return moment(date).valueOf() <  moment().subtract(1, "days").valueOf()
    }
    return false
  },[isDisabledDatePrev])

  const handleChange = useCallback(nextValue => {
    onChange(nextValue && nextValue.toISOString && nextValue.toISOString())
  },[onChange, localValue])

  const datePickerProps = {
    ref: ref,
    onChange: handleChange,
    format: 'DD.MM.YYYY HH:mm',
    showTime: { format: 'HH:mm' },
    value: localValue && moment(localValue),
    disabledDate: disabledDate,
    disabled: disabled,
    ...(picker && { picker: picker })
  };

  return (
    <ConfigProvider locale={locale}>
      <DatePicker {...datePickerProps} />
    </ConfigProvider>
  )
}

export default InputDateTime
