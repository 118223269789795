import {IAction, IErrors} from '../interfaces'
import {
    RESTORE_SESSION_REQUESTED,
    RESTORE_SESSION_FAILED,
    RESTORE_SESSION_DONE,
    DELETE_SESSION_REQUESTED,
    DELETE_SESSION_FAILED,
    DELETE_SESSION_DONE,
    CHANGE_SESSION_JSON_SETTINGS
} from './actions'
import {ISessionState} from "./interfaces";


const initialState: ISessionState = {
    isRestoring: false,
    sessionData: null,
}

export const sessionReducer = (
    state = initialState,
    {type, payload}: IAction<boolean | IErrors | Record<string, any>>,
) => {
    switch (type) {
        case RESTORE_SESSION_REQUESTED:
            return {
                ...state,
                isRestoring: true,
            }
        case RESTORE_SESSION_FAILED:
            return {
                ...state,
                isRestoring: false,
            }
        case RESTORE_SESSION_DONE:
            let jsonSettings = payload['data']['jsonSettings']
            try {
                jsonSettings = JSON.parse(jsonSettings)
            } catch (e) {
                console.log(`error parse ${e}`)
            }
            return {
                ...state,
                isRestoring: false,
                sessionData: {
                    ...payload['data'],
                    jsonSettings: jsonSettings || {}
                },
            }
        case DELETE_SESSION_REQUESTED:
            return {
                ...state,
                isDeleting: true,
            }
        case DELETE_SESSION_FAILED:
            return {
                ...state,
                isDeleting: false,
            }
        case DELETE_SESSION_DONE:
            return {
                ...state,
                isDeleting: false,
                sessionData: null,
            }
        case CHANGE_SESSION_JSON_SETTINGS:
            const json = (() => {
                const jsonSettings = state?.sessionData?.jsonSettings
                function removeEmptyArrays(obj: { [key: string]: any }): void {
                    for (const key in obj) {
                        if (Array.isArray(obj[key]) && obj[key].length === 0) {
                            delete obj[key]
                        }
                    }
                }
                if (payload && typeof payload === 'object') {
                    const settings = { ...jsonSettings, ...payload }
                    removeEmptyArrays(settings)
                    return settings
                }
            })()
            return {
                ...state,
                sessionData: {
                    ...(state?.sessionData || {}),
                    jsonSettings: json,
                }
            }
        default:
            return state
    }
}

