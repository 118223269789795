import React, { useState, useEffect, useMemo, useCallback } from 'react'
import B2BLayout from '../../../layouts/b2b'
import { Table } from 'antd'
import { getColumns } from './columns'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { useHistory, useLocation } from 'react-router-dom'
import FiltersList from '../../../components/v2/filters'
import { Sorter } from '../../../interfaces'
import { objectToUrl } from '../../utils'
import { currentFiltersProps, Enums, IEnum, TablePaginationPosition } from './interface'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getDataOfType } from '../../../../lib/utils/get-data-of-type'
import { getJsonSettingsByKey, TableSettingsButton, TransferItem } from '../../../components/v2/table-settings-button'
import { MontagePrintButton } from '../ui/montage-print-button/montage-print-button'
import { getPrintPath } from '../../../components/v2/export-excel-button'
import { HStack } from '../../../ui/Stack'

const createMenuCounters = createSelector(
  (state: any) => state.menuV2,
  counters => counters,
)

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

const MontageList = () => {
  const history = useHistory()
  const [data, setData] = useState([])
  const [availableFilters, setAvailableFilters] = useState<Enums>({
    manager: [],
    stage: [],
    brand: [],
    office: [],
  })
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))
  const [currentFilters] = useState<currentFiltersProps>({
    phone: urlParams?.phone,
    manager: urlParams?.manager ? +urlParams.manager : undefined,
    id: urlParams.id ? +urlParams.id : undefined,
    brand: urlParams?.brand,
    stage: urlParams?.stage,
    dates: urlParams.dates,
  })
  const { sessionData } = useSelector(createSession)
  const isLeader = getDataOfType(sessionData, 'organization.id', Number, null) === 1

  const [loading, setLoading] = useState(false)
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [stages, setStages] = useState<any>(null)
  const [sorter, setSorter] = useState<Sorter>({})
  const [pagination, setPagination] = useState({
    current: Number(urlParams?.page) || 1,
    pageSize: 10,
    total: 10,
    position: [bottomCenter],
  })
  const {counters} = useSelector(createMenuCounters)
  const [localCounter, setLocalCounter] = useState<null | number>(null)

  const {pathname} = useLocation()
  const jsonSettings = useSelector((state) => getJsonSettingsByKey(state, pathname))


  const areObjectsIdentical = (obj1: Object, obj2: Object): boolean => {
    if (obj1 === obj2) {
      return true
    }

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
      return false
    }

    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) {
      return false
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !areObjectsIdentical(obj1[key], obj2[key])) {
        return false
      }
    }

    return true
  }

  useEffect( () => {
    // устанавливает в локальную переменную текущее состояние счетчиков
    const newCounterData = counters?.['/montage']
    if (newCounterData && !localCounter) {
      setLocalCounter(newCounterData)
    }
    // устанавливает в локальную переменную обновленное состояние счетчиков и подгружает свежие данные, если нужный счетчик обновился
    if (newCounterData && localCounter && newCounterData !== localCounter) {
      setLocalCounter(newCounterData)
      getData()
    }
  }, [counters])

  /**
   * Хлебные крошки
   */
  const breadcrumbs = useMemo(() => {
    return [
      {
        href: location.pathname,
        title: 'Монтажи',
      },
    ]
  }, [])

  useEffect(() => {
    try {
      /* Пытаюсь получить стадии и фильтры */
      getStages()
    } catch (e) {
      alert('Не удалось получить данные, пожалуйста, перезагрузите страницу')
    }
  }, [])

  useEffect(() => {
    if (stages) {
      getData()
      getAvailableFilters()
    }
  }, [stages])

  /**
   * Получает стадии
   */
  const getStages = useCallback(() => {
    if (!stages) {
      fetchAPI('/api/get-stages/montage')
        .then(data => {
          setStages(data)
        })
        .catch(() => {
          console.error('Не удалось получить стадии')
        })
    }
  }, [])

  /**
   * Удаляет запись
   */
  const deleteRecord = useCallback(currentId => {
    fetchAPI(`/api/montage/${currentId}`, { method: 'DELETE' })
      .then(() => {
        getStages()
      })
      .catch(() => {
        console.error('Не удалось удалить запись')
      })
  }, [])


  interface IStage {
    name: string
    code: string
  }
  interface IManager {
    name?: string
    login: string
    id: number
  }

  /**
   * Получает список доступных фильтров для обращений
   */
  const getAvailableFilters = useCallback(() => {
    const enumsList = { types: ['brands', 'offices'] }
    fetchAPI(`/api/enum-list?${objectToUrl(enumsList)}`)
      .then(data => {
        const filters = {
          brand: data?.brands.map((i: IEnum) => ({ label: i.value, value: i.id })) || [],
          office: data?.offices.map((i: IEnum) => ({ label: i.value, value: i.id })) || [],
          stage: stages.map((i: IStage) => ({ label: i.name, value: i.code })),
        }
        setAvailableFilters(filters)
      })
      .catch(e => {
        console.error('Не удалось получить список доступных фильтров',e)
      })
  }, [stages, setAvailableFilters])

  /**
   * Получает записи
   */
  const getData = useCallback(() => {
    if (!stages) {
      getStages()
    }
    try {
      setLoading(true)
      const urlParams = Object.fromEntries(new URLSearchParams(location.search))
      fetchAPI(`/api/montage?${objectToUrl(urlParams)}`).then(({ data, pagination, sorter }) => {
        if (data) {
          setData(
            data.map(item => {
              return {
                ...item,
                deleteRecord,
                stage: stages && stages.find(stage => stage.code === item.stage),
              }
            }),
          )
          setPagination(prevState => ({
            ...prevState,
            ...pagination
          }))
          setSorter(sorter)
        }
        setLoading(false)
      })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [deleteRecord, stages, loading])

  /**
   * Отслеживает изменение состояния таблицы
   *
   * @param pagination
   * @param _filters
   * @param sorter
   */
  const handleTableChange = (pagination, _filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    setPagination(prevState => ({...prevState, ...pagination}))
    const current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current,
      pageSize: pagination.pageSize
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({ search: urlParams })
    getData()
  }

  /**
   * Отправляет на страницу деталки
   * @param record
   */
  const handleRowClick = record => {
    const { id } = record
    history.push(`/montage/${id}`)
  }

  /**
   * Отслеживает состояние фильтров
   * @param filters
   */
  const changeFiltersHandler = useCallback(filters => {
    let tmpFilter = {...(filters || {}), pageSize: pagination.pageSize }
    const newUrlParams = new URLSearchParams(tmpFilter).toString()
    history.push({ search: newUrlParams })
    getData()
  }, [pagination])

  const filters = useMemo(() => {
    const filters = [
      {
        name: 'id',
        placeholder: 'ID',
        type: 'input-integer',
        value: currentFilters?.id,
        options: {
          width: '170px',
        },
      },
      {
        name: 'production_number',
        placeholder: 'Номер производственного заказа',
        type: 'input-string',
        value: currentFilters.production_number || undefined,
      },
      {
        name: 'stage',
        placeholder: 'Фильтр по стадии',
        type: 'select',
        value: currentFilters?.stage,
        options: {
          enum: availableFilters?.stage,
        },
      },
      {
        name: 'dates',
        type: 'dates',
        value: currentFilters?.dates,
      },
    ]

    if (isLeader) {
      filters.unshift({
        name: 'office',
        placeholder: 'Офис',
        type: 'select',
        value: currentFilters?.office,
        options: {
          enum: availableFilters?.office || [],
        },
      })
      filters.unshift({
        name: 'brand',
        placeholder: 'Бренд',
        type: 'select',
        value: currentFilters?.brand,
        options: {
          enum: availableFilters?.brand || [],
        },
      })
    }

    return filters as filtersType[]
  }, [availableFilters, currentFilters, isLeader])

  const columns = useMemo(() => getColumns(isLeader, sorter), [isLeader, sorter])
  const viewColumns: typeof columns = useMemo(() => {
    if (jsonSettings && jsonSettings?.length > 0) {
      let result: any[] = []
      for (let i = 0; i < jsonSettings.length; i++) {
        // @ts-ignore
        let item = columns.find((column) => jsonSettings[i] === column.dataIndex)
        if (item) {
          result.push(item)
        }
      }
      return result
    }
    return columns
  }, [columns, jsonSettings])


  const excelPath = useMemo(() => {
    return getPrintPath('/api/montage', new URLSearchParams(location.search))
  }, [location.search])


  const settingsColumns = useMemo(() => columns.map((column) => ({
    // @ts-ignore
    key: column?.dataIndex, title: column?.title,
  })), [columns]) as TransferItem[]

  return (
    <B2BLayout breadcrumbs={breadcrumbs} isViewScrollButton>
      <FiltersList
        filters={filters}
        onChange={changeFiltersHandler}
      />
      <HStack gap={'24'} align={'start'} justify={'end'} style={{marginBottom: '20px'}}>
        <MontagePrintButton path={excelPath} columns={viewColumns} stages={stages} />
        <TableSettingsButton items={settingsColumns}/>
      </HStack>
      <Table
        showSorterTooltip={{ overlay: 'Нажмите для сортировки', placement: 'bottom' }}
        columns={viewColumns}
        rowKey={r => r?.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        onRow={record => ({ onClick: () => handleRowClick(record) })}
        scroll={{
          x: 'max-content',
        }}
        size='small'
      />
    </B2BLayout>
  )
}

export default MontageList
