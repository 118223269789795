export const RESTORE_SESSION_REQUESTED = 'RESTORE_SESSION_REQUESTED'
export const RESTORE_SESSION_FAILED = 'RESTORE_SESSION_FAILED'
export const RESTORE_SESSION_DONE = 'RESTORE_SESSION_DONE'
export const DELETE_SESSION_REQUESTED = 'DELETE_SESSION_REQUESTED'
export const DELETE_SESSION_FAILED = 'DELETE_SESSION_FAILED'
export const DELETE_SESSION_DONE = 'DELETE_SESSION_DONE'
export const CHANGE_SESSION_JSON_SETTINGS = 'CHANGE_SESSION_JSON_SETTINGS'

export const setJsonSettings = (settings: Record<string, any>) => {
    return (dispatch) => {
        dispatch({ type: CHANGE_SESSION_JSON_SETTINGS, payload: settings })
    }
}