import fetchAPI from '../../../lib/utils/fetch-api'
import {
  setOrderId,
  setOrdersEnumsData,
  setOrdersEnumsFailed,
  setOrdersEnumsLoading, setOrdersFormAlert,
  setOrdersFormData,
  setOrdersFormFailed,
  setOrdersFormLoading,
} from './actions'

export function getOrdersForm(id) {
  return async dispatch => {
    dispatch(setOrdersFormLoading())
    try {
      const response = await fetchAPI(`/api/order/${id}`) || []
      if (response['errors'] || response?.error) {
        let error = response['errors'] || response?.error
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setOrdersFormFailed(error))
      }
      return dispatch(setOrdersFormData(response))
    } catch (err) {
      console.error(err)
      return dispatch(setOrdersFormFailed('При загрузки полей возникла ошибка'))
    }
  }
}

export function nextStage(id, formData) {
  return async dispatch => {
    // dispatch(setOrdersFormLoading())
    try {
      const response =  await fetchAPI(
        `/api/order/${id}`,
        { method: 'POST', body: JSON.stringify(formData) }
      )

      if (response?.['alert']) {
        const alert = response['alert']
        dispatch(setOrdersFormAlert(alert))
        return dispatch(setOrdersFormAlert(null))
      }

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error =  error.join( )
        }
        return dispatch(setOrdersFormFailed(error))
      }

      return dispatch(setOrdersFormData(response))
    } catch (err) {
      return dispatch(setOrdersFormFailed(err as string))
    }
  }
}

export function updateFormData(id, formData) {
  return async dispatch => {
    try {
      const response = await fetchAPI(
        `/api/order/${id}`,
        {
          method: 'POST', body: JSON.stringify(formData)
        }
      )

      if (response?.['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setOrdersFormFailed(error))
      }

      if (response?.id) {
        return dispatch(setOrderId(response.id))
      }

      return
    } catch (err) {
      return dispatch(setOrdersFormFailed(err as string))
    }
  }
}

export function getOrdersEnums(id: string) {
  return async dispatch => {
    dispatch(setOrdersEnumsLoading())
    try {
      const response = await fetchAPI(`/api/order/${id}/enum`)

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setOrdersEnumsFailed(error))
      }

      return dispatch(setOrdersEnumsData(response))
    } catch (err) {
      console.error(err)
      return dispatch(setOrdersEnumsFailed('При загрузки enum возникла ошибка'))
    }
  }
}

export function toClaim(id) {
  return async dispatch => {
    dispatch(setOrdersFormLoading())
    try {
      const response =  await fetchAPI(
        `/api/order/${id}/to_claim`,
        { method: 'POST' },
      )

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error =  error.join( )
        }
        return dispatch(setOrdersFormFailed(error))
      }

      return dispatch(setOrdersFormData(response))
    } catch (err) {
      return dispatch(setOrdersFormFailed(err as string))
    }
  }
}
